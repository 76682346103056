import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, Router} from '@angular/router'
import {DocumentService, CodeInput} from '../../services/document.service'


@Component({
  selector: 'spb-code',
  templateUrl: './code.component.html',
  styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DocumentService
  ) {
  }

  ngOnInit(): void {
    const parts = this.route.snapshot.fragment?.split('&') as string[]
    const request: CodeInput = {
      code: parts[0].split('=')[1],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      id_token: parts[1].split('=')[1],
      state: parts[2].split('=')[1]
    }
    this.dataService.code(request).subscribe({
      next: (res: string) =>
        this.router.navigate(['documents', res])
    })
  }

}
