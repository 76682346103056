import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

export interface WaitDialogData {
  /**
   * The title to show
   */
  title: string

  /**
   * The messae to show
   */
  message: string

  // Show the close button, default we do not.
  close?: boolean
}

@Component({
  selector: 'spb-wait-dialog',
  templateUrl: './wait-dialog.component.html'
})
export class WaitDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: WaitDialogData) {
  }
}
