<div>
  <div class="top-holder">
  </div>

  <div class="middle-holder">
    <div class="middle">
      <div [routerLink]="['/start']" class="logo">
        Borgo
      </div>

      <div class="middle">
        <div class="spb-filler"></div>
      </div>
    </div>
  </div>
</div>
