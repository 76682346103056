import {Component, OnInit} from '@angular/core'
import {ActivatedRoute, ParamMap} from '@angular/router'
import {DocumentService, BorgoDocument} from '../../services/document.service'
import {switchMap} from 'rxjs/operators'

@Component({
  selector: 'spb-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  public documents: BorgoDocument[] = []

  constructor(
    private route: ActivatedRoute,
    private dataService: DocumentService
  ) {
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        const id = params.get('id')
        return this.dataService.documents(id as string)
      })
    ).subscribe({
      next: (res: BorgoDocument[]) => {
        this.documents = res
      }
    })
  }

  public approve(doc: BorgoDocument): void {
    console.log(doc)
  }
}
