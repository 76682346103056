import {Injectable} from '@angular/core'
import {Observable} from 'rxjs'
import {environment} from '../../environments/environment'
import {HttpClient} from '@angular/common/http'

export interface LoginResponse {
  url: string
}

export interface CodeInput {
  /**
   * The authorization code
   */
  code: string

  /**
   * The ID token, contains information about the user. Ish.
   */
  // eslint-disable-next-line @typescript-eslint/naming-convention
  id_token: string

  /**
   * A "nonce" that we should verify, but we do not :-p
   */
  state: string
}

/**
 * TODO: Use the backends
 */
export interface BorgoDocument {
  /**
   * The document as base64 shite.
   */
  doc: string

  /**
   * Hasch, the document hash to be signed
   */
  hash: string

  title: string

  id: string
}

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private httpClient: HttpClient) {
  }

  /**
   * Start the login procedure, it will trigger a long line
   * of 302s and eventually end up in /code
   */
  public login(): Observable<LoginResponse> {
    const url = `${environment.apiUrl}/login-link`
    return this.httpClient.get<LoginResponse>(url)
  }

  /**
   * Send the code and get something back
   */
  public code(input: CodeInput): Observable<string> {
    const url = `${environment.apiUrl}/code`
    return this.httpClient.post<string>(url, input)
  }

  /**
   * Get documents
   */
  public documents(id: string): Observable<BorgoDocument[]> {
    const url = `${environment.apiUrl}/users/${id}/documents`
    return this.httpClient.get<BorgoDocument[]>(url)
  }
}
