import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {START_ROUTE_PATH} from './application/data-types'
import {CodeComponent} from './common/code/code.component'
import {DocumentsComponent} from './common/documents/documents.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: START_ROUTE_PATH
  },
  {
    /**
     * Very special path. That snoops the '#' from the url
     * sends the code to be and then routes to "documents"
     */
    path: 'code',
    component: CodeComponent
  },
  {
    path: 'documents/:id',
    component: DocumentsComponent
  },
  {
    path: START_ROUTE_PATH,
    loadChildren: () => import('./0-start/start.module').then(m => m.StartModule)
  },
  {
    path: '**',
    redirectTo: START_ROUTE_PATH
  }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    useHash: false
    //scrollPositionRestoration: 'enabled',
    //anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
