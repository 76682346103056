import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {MatDialogModule} from '@angular/material/dialog'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import {WaitDialogComponent} from './wait-dialog/wait-dialog.component'
import {MatButtonModule} from '@angular/material/button'
import {HeaderComponent} from './header/header.component'
import {MatMenuModule} from '@angular/material/menu'
import {MatIconModule} from '@angular/material/icon'
import {SafePipe} from './safe-pipe/safe.pipe'
import {ProgressComponent} from './progress/progress.component'
import {DocumentsComponent} from './documents/documents.component'
import {CodeComponent} from './code/code.component'

@NgModule({
  declarations: [
    WaitDialogComponent,
    HeaderComponent,
    SafePipe,
    ProgressComponent,
    DocumentsComponent,
    CodeComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule
  ],
  exports: [
    SafePipe,
    HeaderComponent,
    ProgressComponent
  ]
})

export class SpbCommonModule {
}
