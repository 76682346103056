<div class="holder">
  <spb-progress *ngIf="documents.length === 0" text="Hämtar dokument för underskrift"></spb-progress>
  <div class="documents">
    <div *ngFor="let doc of documents; let index = index">
      <div class="document-title">{{doc.title}}</div>
      <object
        class="pdf"
        [id]="'pdf'+index"
        [data]="'data:application/pdf;base64,' + doc.doc | safe" type="application/pdf">
        <p>The document {{index}}</p>
      </object>
      <button mat-stroked-button (click)="approve(doc)">Godkänn</button>
    </div>
  </div>
</div>
